@charset "UTF-8";
.mean-container .mean-bar {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  min-height: 60px;
  position: absolute;
  z-index: 9; }

.mean-container a.meanmenu-reveal {
  background-image: url("../images/elements/menu2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px auto;
  height: 30px;
  padding: 0;
  top: 10px;
  width: 50px; }
  .mean-container a.meanmenu-reveal.meanclose {
    background-image: url("../images/elements/close2.png");
    background-size: 25px auto;
    text-indent: -9999px !important; }
  .mean-container a.meanmenu-reveal span {
    display: none; }

.mean-container .mean-nav {
  margin-top: 52px; }
  .mean-container .mean-nav ul li a {
    background-color: #eee;
    border-top: 1px solid #bbb;
    color: #232323;
    letter-spacing: 0.7px;
    line-height: 1;
    padding: 1em 5%; }
    .mean-container .mean-nav ul li a:hover {
      background-color: #ccc; }
  .mean-container .mean-nav ul .mean-last {
    box-shadow: 0 7px 11px -7px #777; }

#header .region-header {
  padding: 0;
  width: auto; }

#block-block-24 h2 {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 10; }
  #block-block-24 h2 a {
    border-bottom: 3px solid #e31e24;
    border-top: 3px solid #e31e24;
    height: 24px;
    width: 200px; }

#menu-bar {
  padding: 50px 0 0; }

#block-block-2 {
  margin: auto;
  padding: 0 10px 20px;
  width: auto; }
  #block-block-2 h1 {
    font-size: 20px;
    letter-spacing: 0.3px;
    line-height: 1.2;
    margin: -340px 0 0 -10px;
    padding: 0 5px;
    position: absolute;
    text-align: center;
    width: auto; }
  #block-block-2 .image {
    height: 280px;
    margin: 80px auto 0;
    position: relative;
    width: auto; }
  #block-block-2 .text {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin: 0 0 20px;
    text-align: center;
    width: auto; }
  #block-block-2 .ssilka {
    text-align: center;
    width: 100%; }
    #block-block-2 .ssilka a {
      font-size: 14px;
      padding: 8px 20px; }
  #block-block-2 .podpis {
    font-size: 13px;
    margin: 7px 0 0;
    text-align: center; }

#block-block-26 {
  padding: 30px 10px; }
  #block-block-26 .content {
    margin: auto;
    width: auto; }
  #block-block-26 h2 {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    width: auto;
    margin: 0 0 35px; }
    #block-block-26 h2::after {
      height: 5px;
      margin: 15px 0 0 43%;
      width: 12%; }
  #block-block-26 .text {
    font-size: 14px;
    line-height: 1.3;
    width: auto; }
  #block-block-26 .ssilka {
    padding: 30px 0 10px; }
    #block-block-26 .ssilka a {
      font-size: 14px;
      padding: 8px 20px; }

#block-block-27 {
  background-attachment: scroll;
  background-size: 140% auto, auto auto;
  padding: 30px 0; }
  #block-block-27 .content {
    margin: auto;
    width: auto; }
  #block-block-27 h2 {
    color: #232323;
    font-size: 24px;
    line-height: 1.1;
    margin: 0 0 35px;
    padding: 0 10px;
    text-align: center;
    width: auto; }
    #block-block-27 h2::after {
      height: 5px;
      margin: 15px 0 0 40%;
      width: 16%; }
  #block-block-27 .text {
    color: #000;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    margin: 250px 0 0;
    padding: 0 10px;
    text-align: center;
    width: auto; }

#block-block-28 {
  padding: 30px 0; }
  #block-block-28 .content {
    padding: 0;
    width: auto; }
  #block-block-28 .image {
    background-image: url("../images/diagram.jpg");
    background-position: center center;
    background-size: auto 100%;
    height: 180px;
    margin: 100px 0 30px;
    position: relative;
    text-indent: -9999px;
    width: 100%; }
  #block-block-28 .legend {
    left: 0;
    margin: 0;
    position: relative;
    width: auto; }
    #block-block-28 .legend .leglast {
      font-size: 13px;
      letter-spacing: 0.5px;
      margin: 0 0 30px;
      position: relative;
      width: auto; }
  #block-block-28 .leg1,
  #block-block-28 .leg2,
  #block-block-28 .leg3,
  #block-block-28 .leg4,
  #block-block-28 .leg5,
  #block-block-28 .leg6,
  #block-block-28 .leg7 {
    display: none; }
  #block-block-28 h2 {
    font-size: 24px;
    margin: -350px 0 0;
    padding: 0 10px;
    position: absolute;
    text-align: center; }
    #block-block-28 h2::after {
      height: 5px;
      margin: 10px 0 0 38%;
      width: 16%; }
  #block-block-28 .text {
    padding: 0 10px; }
    #block-block-28 .text p {
      font-size: 14px;
      line-height: 1.3;
      margin: 0 0 5px; }
    #block-block-28 .text ul {
      margin: 0; }
    #block-block-28 .text li {
      font-size: 14px;
      line-height: 1.3;
      margin: 0 0 3px; }

#block-block-30 {
  padding: 30px 0; }
  #block-block-30 .content {
    margin: auto;
    width: auto; }
  #block-block-30 .image {
    background-position: 0 0;
    background-size: auto 100%;
    height: 250px;
    margin: -60px 0 0 60%;
    width: 40%; }
  #block-block-30 h2 {
    font-size: 18px;
    line-height: 1.5;
    margin: 40px 0 70px 10px;
    width: 50%; }
    #block-block-30 h2::after {
      height: 5px;
      margin: 15px 0 0;
      width: 16%; }
  #block-block-30 ul {
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    padding: 0 10px 0 20px;
    width: auto; }
    #block-block-30 ul li {
      margin: 0 0 10px; }
  #block-block-30 .ssilka {
    margin: 30px 0 10px;
    text-align: center; }
    #block-block-30 .ssilka a {
      font-size: 14px;
      padding: 8px 20px; }

#block-block-31 {
  padding: 30px 10px; }
  #block-block-31 .content {
    margin: auto;
    width: auto; }
  #block-block-31 h2 {
    font-size: 24px;
    line-height: 1.1;
    margin: 0 auto 40px;
    text-align: center;
    width: 100%; }
    #block-block-31 h2::after {
      height: 5px;
      margin: 20px 0 0 40%;
      width: 12%; }
  #block-block-31 p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0 auto;
    text-align: center;
    width: auto; }

#block-block-32 {
  padding: 30px 0 10px; }
  #block-block-32 .content {
    margin: auto;
    width: auto; }
  #block-block-32 h2 {
    font-size: 24px;
    margin: 0 0 60px;
    padding: 0 10px; }
    #block-block-32 h2::after {
      height: 5px;
      margin: 20px 0 0 40%;
      width: 12%; }
  #block-block-32 tbody td {
    font-size: 10px;
    line-height: 1.3;
    padding: 10px 5px; }
  #block-block-32 thead th {
    padding: 0; }
    #block-block-32 thead th span {
      border: 2px solid;
      font-size: 28px;
      height: 40px;
      padding: 10px 0 0;
      width: 50px; }
  #block-block-32 .links {
    margin: 30px 0 10px; }
    #block-block-32 .links > div {
      margin: 0 5px 20px; }
    #block-block-32 .links a {
      font-size: 14px;
      padding: 8px 20px; }
  #block-block-32 .link3 a {
    padding: 3px 0; }

#block-block-33, #block-block-36 {
  padding: 30px 10px; }
  #block-block-33 .content, #block-block-36 .content {
    margin: auto;
    width: auto; }
  #block-block-33 h2, #block-block-36 h2 {
    font-size: 24px;
    margin: 0 0 40px; }
    #block-block-33 h2::after, #block-block-36 h2::after {
      height: 5px;
      margin: 15px 0 0 40%;
      width: 12%; }
  #block-block-33 .text, #block-block-36 .text {
    font-size: 16px;
    line-height: 1.5; }
  #block-block-33 .ssilka, #block-block-36 .ssilka {
    margin: 20px 0 10px; }
    #block-block-33 .ssilka a, #block-block-36 .ssilka a {
      font-size: 14px;
      padding: 8px 20px; }

#footer {
  padding: 20px 10px; }
  #footer .region {
    margin: auto;
    width: auto; }

#block-block-34 h2 a {
  height: 24px;
  margin: 20px auto;
  width: 200px; }

#block-superfish-2 {
  display: none; }

.not-front .mean-bar {
  border-bottom: 2px dashed #bbb;
  min-height: 48px; }
  .not-front .mean-bar .mean-nav {
    margin-top: 48px; }

.not-front #header {
  border: none; }
  .not-front #header .region-header {
    padding: 0; }

.not-front #content-column {
  margin: auto;
  padding: 0;
  width: auto; }

.page-node-395 h1#page-title {
  font-size: 34px;
  padding: 50px 0 30px; }

.page-node-395 .node .field-name-body .table > div {
  display: block;
  height: 140px;
  margin: 0 0 20px 10px;
  overflow: hidden;
  padding: 0 5px 0 140px;
  width: 50%; }

.page-node-395 .node .field-name-body .table .zagolovok {
  font-size: 20px;
  left: 10px;
  padding: 20px 0 100px;
  position: absolute;
  width: 120px; }
  .page-node-395 .node .field-name-body .table .zagolovok::after {
    border-color: transparent transparent transparent #55c862;
    border-style: solid;
    border-width: 70px 20px;
    height: 0;
    margin: -40px 0 0 120px;
    width: 0; }

.page-node-395 .node .field-name-body .table .opis {
  font-size: 10px;
  left: 10px;
  margin: 50px auto 0 0;
  padding: 0;
  width: 120px; }

.page-node-395 .node .field-name-body .table .price {
  font-size: 26px;
  left: 10px;
  margin: 90px 0 0;
  width: 120px; }
  .page-node-395 .node .field-name-body .table .price span {
    font-size: 12px; }

.page-node-395 .node .field-name-body .table .text {
  padding: 15px 0 0; }
  .page-node-395 .node .field-name-body .table .text ul {
    padding: 0 0 0 25px; }
    .page-node-395 .node .field-name-body .table .text ul li {
      font-size: 12px;
      letter-spacing: 0.3px;
      line-height: 1.3;
      margin: 0 0 10px; }
      .page-node-395 .node .field-name-body .table .text ul li::before {
        height: 12px;
        margin: 4px 0 0 -17px;
        width: 12px; }

.page-node-395 .node .field-name-body .table2 {
  margin: 30px 0; }
  .page-node-395 .node .field-name-body .table2 ul {
    padding: 0 10px; }
    .page-node-395 .node .field-name-body .table2 ul li {
      background-size: 18px auto;
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 1.5;
      padding: 0 0 0 35px; }

.page-node-395 .node .field-name-body .ssilki {
  margin: 40px 0 50px; }
  .page-node-395 .node .field-name-body .ssilki > div {
    display: block;
    margin: 0 auto 10px; }
  .page-node-395 .node .field-name-body .ssilki a {
    display: block;
    font-size: 14px;
    margin: 0 auto;
    padding: 8px 20px;
    width: 150px; }
  .page-node-395 .node .field-name-body .ssilki .ssilka1 a {
    padding: 4px 20px; }

.page-node-396 .node .field-name-body {
  margin: 10px 0 30px; }
  .page-node-396 .node .field-name-body p {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 10px; }

#block-block-37, #block-block-38, #block-block-39 {
  margin: 30px auto 50px;
  width: auto; }
  #block-block-37 input.button, #block-block-38 input.button, #block-block-39 input.button {
    font-size: 18px;
    margin: 20px 0 10px; }

#block-block-37 {
  margin: 0 auto 50px;
  padding: 30px 10px; }

.page-node-396 h1#page-title,
.page-node-397 h1#page-title,
.page-node-399 h1#page-title {
  font-size: 30px;
  padding: 70px 10px 10px; }

#block-block-39 .contacts > div {
  padding: 0 0 0 60px !important; }

#block-block-37 span.required,
#block-block-38 span.required,
#block-block-39 span.required {
  border: none;
  color: #e31e24;
  font-size: 20px;
  top: 15px; }

#block-block-38 .dopinfo {
  padding: 0; }
  #block-block-38 .dopinfo li {
    background-size: 20px auto;
    font-size: 14px;
    margin: 0 0 30px;
    padding: 0 0 0 35px; }

#block-block-55 {
  display: none; }
  #block-block-55 .close {
    display: none; }

.node-type-rabota .views-row .views-field-nothing .fancybox {
  width: 47%;
  float: left;
  text-align: center; }

/*Афоризмы в футурe*/
#footer #block-views-aforizmy-block {
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  margin-right: 0; }
  #footer #block-views-aforizmy-block .view-content .field-content {
    color: #fff; }

/*контакты*/
.page-node-407 #content {
  padding: 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
